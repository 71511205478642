import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/beer-factory-blog/beer-factory-blog/src/templates/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`votre assistant brasseur`}</h1>
    <p>{`Pour ce premier billet, faisons connaissance :)`}</p>
    <p>{`Je m'appelle Quentin, je suis le créateur de Beer Factory. Ce projet est né il y a environ 3 ans. Au départ, ce n'était qu'une petite application mobile développée dans mon coin.`}</p>
    <p>{`Le but ? Simplifier mon processus de brassage et m'assister dans mes premières expériences brassicoles. Ce fut donc en prenant ceci comme un jeu, permettant de mettre en pratique mes nouvelles connaissances et ma passion pour le monde brassicole que l'application est née.
Après quelques temps, l'utilisation de Beer factory devint systématique pendant mes séances de brassages. Peut-être pourrait-elle être utile à d'autres! Partant de ce constat, je choisis de la déployer sur le play store. L'application est aujourd'hui utilisée par des centaines de personnes ce qui m'a motivé à continuer et à passer à l'étape suivante :)`}</p>
    <h1>{`Vers des outils plus évolués`}</h1>
    <p>{`Au fil du temps mes activités brassicoles ont évolué grâce au lancement de Brasse Band, une brasserie associative fondée avec un groupe de brasseurs amateurs Auvergnats !`}</p>
    <p>{`Pour l'occasion Beer Factory c'est décliné en application web et a intégré des outils de gestion de commandes, eShop, etc.`}</p>
    <p>{`Les amateurs n'ont pas été oubliés pour autant ! Nouveaux outils de calculs, blog et un éditeur de recettes sont désormais disponibles ! Et ce n'est pas fini, de nombreuses nouveautés devraient bientôt faire leurs apparitions !`}</p>
    <h1>{`Conclusion`}</h1>
    <p>{`Aujourd'hui, par ce billet, c'est une nouvelle étape qui est lancée, elle s'inscrit dans la volonté de partage et d'assistance ancrée dans l'ADN de Beer Factory! Nous y verrons les rudiments du monde brassicole, des articles pour apprendre à utiliser les fonctionnalités les plus complexes du site et partager des retours d'expériences, astuces, tests...`}</p>
    <p>{`J'espère que ce programme vous met l'eau à la bouche ! N'hésitez pas à laisser un commentaire pour vous présenter ou simplement si vous avez envie ! À bientôt sur Beer Factory ;)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      